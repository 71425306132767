div#bg {
  margin: 0px auto;
  position: relative;
  z-index: 2;
}
/* .bg:before {
  background-size: 100%;
  background-position: 5px;
  content: "";
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  width: 365px;
  height: 365px;
} */
.mainbox {
  max-width: 430px;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  top: 25px;
  margin: 0 auto;
  z-index: 2;
  right: 0;
  padding: 10px;
}
.mainbox:after {
  position: absolute;
  content: "";
  background: url(../../assets/rotaryNeedle.png) no-repeat;
  background-size: cover;
  z-index: 9999 !important;
  transform: translateY(-50%);
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 62.07822036743164px;
  width: 40.84272384643555px;
  border-radius: 0px;
}
.box {
  width: 100%;
  height: 100%;
  position: relative;
  top: 20px;
  border-radius: 50%;
  /* border: 8px solid #b01817; */
  overflow: hidden;
  transition: all ease 5s;
  box-sizing: border-box;
  background: url(../../assets/wheel_bg.png) no-repeat;
  background-position: center;
  background-size: contain;
  /* margin-top: 30px; */
}

span.segment {
  width: 50%;
  height: 50%;
  display: inline-block;
  position: absolute;
}
.box1 .span1 {
  top: 42px;
  left: -42px;
}
.box2 .span1 {
  top: 155px;
  left: -35px;
}
.box1 .span2 {
  /* clip-path: polygon(100% 92%, 0 49%, 100% 8%); */
  top: 55px;
  right: -48px;
}
.box2 .span2 {
  /* clip-path: polygon(100% 92%, 0 49%, 100% 8%); */
  top: 115px;
  right: -40px;
}
.box1 .span3 {
  /* clip-path: polygon(50% 0%, 8% 100%, 92% 100%); */
  /* clip-path: polygon(50% 0%, 8% 100%, 92% 100%); */
  bottom: -15px;
  left: 165px;
}
.box2 .span3 {
  /* clip-path: polygon(50% 0%, 8% 100%, 92% 100%); */
  bottom: -39px;
  left: 103px;
}
.box1 .span4 {
  /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
  top: -35px;
  left: 95px;
}
.box2 .span4 {
  /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
  top: -25px;
  left: 155px;
}

.box1 .span5 {
  /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
  bottom: -5px;
  left: 10px;
}
.box2 .span5 {
  /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
  top: -10px;
  left: 15px;
}

span.segment.span1.color-1 {
  transform: rotate(-72deg);
}
span.segment.span2.color-1 {
  transform: rotate(72deg);
}
span.segment.span3.color-1 {
  transform: rotate(144deg);
}
span.segment.span4.color-1 {
  transform: rotate(0deg);
}
span.segment.span5.color-1 {
  transform: rotate(-144deg);
}

span.segment.span1.color-2 {
  transform: rotate(-114deg);
}
span.segment.span2.color-2 {
  transform: rotate(96deg);
}
span.segment.span3.color-2 {
  transform: rotate(172deg);
}
span.segment.span4.color-2 {
  transform: rotate(24deg);
}
span.segment.span5.color-2 {
  transform: rotate(-45deg);
}

.color-1 {
  /* background-color: #f6de8b; */
  color: #ffffff;
  font-weight: bold;
}
.color-2 {
  /* background-color: #c12528; */
  color: #ffffff;
  font-weight: bold;
}

.box2 {
  width: 100%;
  height: 100%;
  transform: rotate(-135deg);
}

.span1 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 47%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span2 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 60%;
  left: 49%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span3 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 43%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span4 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 52%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.span5 i.segments {
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 55%;
  left: 43%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0px;
}

.segments img {
  max-width: 40px;
  max-height: 50px;
  margin-top: 10px;
  top: 0px;
  right: 35px;
}
.segments h6 {
  display: block;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  left: 55px;
  line-height: 13px;
  text-align: center;
  top: -23px;
  width: 90px;
  text-align: center;
}
.spin {
  outline: none;
  position: absolute;
  top: 55%;
  transform: translate(-50%, -50%);
  /* width: 70px;
  height: 70px; */
  border: none;
  /* background: url(../../assets/spinButton.png) no-repeat; */
  background-position: center;
  background-size: cover;
  cursor: pointer;
  left: 50%;
  background-color: transparent;

  -webkit-tap-highlight-color: transparent;
}
.spin > img {
}

button.spin {
  border-radius: 30px;
}

/* div#bg:after {
  background-size: 100%;
  display: block;
  content: "";
  height: 365px;
  width: 300px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 103px;
  z-index: -1;
  background-position: 3px 30px;
} */

.mainbox.animate:after {
  animation: animateArrow 0.7s ease infinite;
}
@keyframes animateArrow {
  50% {
    right: -40px;
  }
}
@media only screen and (max-width: 360px) {
  .bg:before {
    left: -16px;
    background-position: -7px;
  }
  .mainbox {
    left: -15px;
  }
  div#bg:after {
    left: -11px;
  }
}
@media (min-width: 361px) and (max-width: 375px) {
  .bg:before {
    left: -20px;
  }
  .mainbox {
    left: 0;
  }
  div#bg:after {
    left: -5px;
  }
}

/* aaa */
.wheel-container-vongquay {
  margin-top: 0px;
}
.wheel {
  position: relative;
  /* top: -10px; */
  background-image: url(../../assets/bg-wheel.png);
  width: 100vw;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.btn-luotquay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  gap: 10px;

  width: 100%;
  height: 48px;

  background: #f6de8b;
  border: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  color: #d73727;
  margin-top: 60px;
}

.hetluotquay-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  text-align: center;

  color: #f6de8b;
  margin-top: 20px;
}
.spin-remain-container {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spin-remain-container.container {
  min-height: 0;
}

.spin-remain-container .spin-remain {
  padding: 16px 40px;
  background: #1a6a36;
  border: 4px solid #fff69a;
  border-radius: 16px;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}

@media only screen and (max-width: 375px) {
  segments h6 {
    font-size: 12px;
  }
  .segments img {
    max-width: 30px;
  }
  .box1 .span3 {
    bottom: -10px;
    left: 150px;
  }
  .box1 .span4 {
    /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
    top: -35px;
    left: 85px;
  }
  .box1 .span5 {
    bottom: 5px;
    left: 0px;
  }
  .box2 .span1 {
    top: 150px;
    left: -35px;
  }
  .box2 .span3 {
    bottom: -30px;
    left: 100px;
  }
  .box2 .span4 {
    /* clip-path: polygon(48% 100%, 92% 0, 8% 0); */
    top: -15px;
    left: 140px;
  }
  .box2 .span5 {
    top: 2px;
    left: 8px;
  }
}
